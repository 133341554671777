import React, {useRef, useState} from 'react';
import {closestCenter, DndContext, DragEndEvent} from '@dnd-kit/core';
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {ImageChanges} from './imageChanges';
import {ImageItem} from './ImageItem';

const SortableItem: React.FC<{
  id: string;
  image: ImageItem;
  onRemove: (image: ImageItem) => void;
}> = ({id, image, onRemove}) => {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{...style, position: 'relative', margin: '10px', width: '30%'}}
      className="d-inline-block"
    >
      <img
        src={image.url}
        alt="Uploaded"
        style={{
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          borderRadius: '8px',
          border: '1px solid #ddd'
        }}
      />
      <div
        className="position-absolute top-0 end-0 m-2"
        style={{
          zIndex: 10,
          cursor: 'pointer',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '50%',
          padding: '4px'
        }}
        onMouseDown={(event) => {
          // Stop drag events from starting if the user clicks delete
          event.stopPropagation();
          onRemove(image);
        }}
      >
        {/* Simple "X" icon */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="20px" height="20px">
          <path
            d="M19.3 4.71a1 1 0 0 0-1.41 0L12 10.59 6.12 4.71A1 1 0 0 0 4.71 6.12L10.59 12l-5.88 5.88a1 1 0 0 0 1.41 1.41L12 13.41l5.88 5.88a1 1 0 0 0 1.41-1.41L13.41 12l5.88-5.88a1 1 0 0 0 0-1.41z" />
        </svg>
      </div>
    </div>
  );
};

export const ImageGrid = ({
                            initialImages = [],
                            onImageChange
                          }: {
  initialImages?: string[];
  onImageChange: (images: ImageChanges) => void;
}) => {

  const initialImageItems: ImageItem[] = initialImages.map((id) => ({
    id,
    url: `${process.env.REACT_APP_BASE_URL}/images/${id}`,
    fromServer: true
  }));

  const [images, setImages] = useState<ImageItem[]>(initialImageItems);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (!files.length) return;

    Promise.all(
      files.map((file) =>
        new Promise<{file: File; dataUrl: string}>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({file, dataUrl: reader.result as string});
          };
          reader.readAsDataURL(file);
        })
      )
    ).then((results) => {
      // Build new ImageItem objects
      const newItems: ImageItem[] = results.map(({file, dataUrl}) => ({
        id: `temp-${Date.now()}-${file.name}`,
        url: dataUrl,
        fromServer: false,
        file
      }));

      // 1) Compute the new array
      const updatedImages = [...images, ...newItems];

      // 2) Set state
      setImages(updatedImages);

      // 3) Call your parent callback with the newly updated array
      onImageChange({
        images: updatedImages,
        deletedImages
      });
    });
  };

  const handleRemove = (image: ImageItem) => {
    // 1) Calculate your new states (images, deletedImages, newImages) in local variables
    const itemToRemove = images.find((item) => item.id === image.id);
    if (!itemToRemove) return; // not found, do nothing

    // Remove from 'images'
    const updatedImages = images.filter((item) => item.id !== image.id);

    let updatedDeletedImages = deletedImages;

    if (itemToRemove.fromServer) {
      updatedDeletedImages = [...deletedImages, itemToRemove.id];
    } else {
    }

    setImages(updatedImages);
    setDeletedImages(updatedDeletedImages);

    onImageChange({
      images: updatedImages,
      deletedImages: updatedDeletedImages
    });
  };

  /**
   * Handle drag-and-drop reordering
   */
  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (!over || active.id === over.id) return;

    // @ts-ignore
    const oldIndex = parseInt(active.id, 10);
    // @ts-ignore
    const newIndex = parseInt(over.id, 10);

    const updatedImages = arrayMove(images, oldIndex, newIndex).filter((item) => item !== undefined);
    setImages(updatedImages);

    onImageChange({
      images: updatedImages,
      deletedImages
    });
  };

  return (
    <div className="container mt-5">
      <div className="alert alert-info" role="alert">
        <div className="d-flex">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon alert-icon"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
              <path d="M12 9h.01"></path>
              <path d="M11 12h1v4h1"></path>
            </svg>
          </div>
          <div>
            <h4 className="alert-title">Bilder für die Gewässer der Gastkarte</h4>
            <div className="text-secondary">
              Lade hier Bilder hoch, die mit der Gastkarte beangelt werden dürfen. Die Bilder werden in der Reihenfolge
              präsentiert, wie man liest. Die Reihenfolge kann via Drag and Drop verändert werden.
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="imageUpload" className="form-label">
          Upload Images
        </label>
        <div>
          <input
            type="file"
            className="form-control"
            id="imageUpload"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </div>
      </div>

      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={images.map((_, index) => index.toString())}
          strategy={verticalListSortingStrategy}
        >
          <div className="d-flex flex-wrap justify-content-start">
            {images
              .filter((img): img is ImageItem => !!img)  // Keep only truthy, and narrow type
              .map((image, index) => (
                <SortableItem
                  key={index.toString()}
                  id={index.toString()}
                  image={image}
                  onRemove={handleRemove}
                />
              ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};
